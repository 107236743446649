<div class="fullscreen-bg">
  <video 
    #vid
    class="fullscreen-bg__video"
    loop="true"
    muted="muted" 
    autoplay="autoplay" 
    oncanplay="this.play()" 
    onloadedmetadata="this.muted = true">
      <!-- <source src="video/big_buck_bunny.webm" type="video/webm"> -->
      <source src="https://keiken-bucket.s3-ap-southeast-2.amazonaws.com/LandingVideo.mp4" type="video/mp4">
      <!-- <source src="video/big_buck_bunny.ogv" type="video/ogg"> -->
  </video>
</div>