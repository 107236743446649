<!-- DESKTOP VIEW -->
<div class="desktop" id="styles">
  <div class="container-grid">

    <div>
  
      <h1 class="m-b-h">Styles</h1>
  
      <p class="m-b-1">Capture your style and create an atmosphere your guests <br class="show-large-screen"/>will love with our range of inspired
        styles.</p>
  
      <div class="m-y-1h flex align-center">
        <img class="m-r-1" src="../../../assets/earthglow.png" alt="Earth Glow colour scheme">
        <h2>Earth Glow</h2>
      </div>
  
      <div class="m-y-1h flex align-center">
        <img class="m-r-1" src="../../../assets/natralis.png" alt="Natralis colour scheme">
        <h2>Naturalis</h2>
      </div>
  
      <div class="m-y-1h flex align-center">
        <img class="m-r-1" src="../../../assets/coastalair.png" alt="Coastal Air colour scheme">
        <h2>Coastal Air</h2>
      </div>
  
      <div class="m-t-1h">
        <button class="book-button clicky" (click)="navContact()">Book</button>
      </div>
  
      <img id="hand-img" src="../../../assets/earthglowhand.png" alt="Hand Image">
  
    </div>
  
    <div>
  
      <img id="styles-img" src="../../../assets/stylesimg.png" alt="Styles Img">
  
    </div>
  
  </div>
  
</div>


<!-- MOBILE VIEW -->

<div class="mobile" style="height: 700px;" id="styles2">
  <div class="container-grid">

    <div class="m-b-1 flex align-center justify-space-between">
      <h1>Styles</h1>
      <button class="book-button clicky" (click)="navContact()">Book</button>
    </div>

    <div class="m-b-1">
      <p>Capture your style and create an atmosphere your guests will love with our range of inspired
        styles.</p>
    </div>

    <div class="m-b-1 styles-img-container">
      <img class="styles-img" src="../../../assets/stylesimg.png" alt="Styles Img">
    </div>

    <div class="theme-grid">
      
      <div>
        <div class="m-y-h flex align-center">
          <img class="m-r-1" src="../../../assets/earthglow.png" alt="Earth Glow colour scheme">
          <h2>Earth Glow</h2>
        </div>
    
        <div class="m-y-h flex align-center">
          <img class="m-r-1" src="../../../assets/natralis.png" alt="Natralis colour scheme">
          <h2>Naturalis</h2>
        </div>
    
        <div class="m-y-h flex align-center">
          <img class="m-r-1" src="../../../assets/coastalair.png" alt="Coastal Air colour scheme">
          <h2>Coastal Air</h2>
        </div>
      </div>

      <div class="flex justify-center align-center">
        <img src="../../../assets/earthglowhand.png" alt="Hand Image">
      </div>
    </div>
  
  </div>
</div>