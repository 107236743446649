<div class="container-grid">

  <div class="right">
    <h1 class="m-b-h">Locations</h1>
    <p class="m-b-1">Botanica is based in Abottsford Melbourne servicing the greater Melbourne, Peninsular & Geelong Regions.</p>
    <p class="m-b-1"> Our pop up picnic experiences are delivered and set up in your chosen public park or private location.</p>
  </div>

  <div class="left">
    <h1 class="m-b-h">Capacities</h1>
    <p class="m-b-1 top-padding">Botanica currently offers 2 capacity options when booking picnic packages.</p>
    <p>The connect package is suited for 2 -6  attendees offering an intimate single table layout. The gather package is suited to parties of 6 - 12 offering a double table or one extended banquet style table.</p>
  </div>

</div>