import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() isMobile: boolean 

  constructor() {

  }

  ngOnInit(): void {

  }

  navStyle() {
    let el = this.isMobile ? document.getElementById('styles2') as HTMLElement : document.getElementById('styles') as HTMLElement
    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }
  navExtras() {
    let el = this.isMobile ? document.getElementById('extras2') as HTMLElement : document.getElementById('extras') as HTMLElement
    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }
  navContact() {
    let el = this.isMobile ? document.getElementById('contact2') as HTMLElement : document.getElementById('contact') as HTMLElement
    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

}
