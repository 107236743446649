import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.scss']
})
export class StylesComponent implements OnInit {
  @Input() isMobile: boolean
  
  constructor() { }

  ngOnInit(): void {
  }

  navContact() {
    let el = this.isMobile ? document.getElementById('contact2') as HTMLElement : document.getElementById('contact') as HTMLElement
    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

}
