import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDateMask]'
})
export class DateDirectiveDirective {

  constructor(el: ElementRef) {
    
  }

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    // Sanitise Data
    let split = input.value.replace(/\D/g, '').split('').filter(c => {
      return c != '/'
    })

    // Add '/'
    let s = ''
    for (const [i, value] of split.entries()) {
      if (i == 2 || i == 4) { s += '/' }
      s += value
    }

    return (input.value = s)
  }
  
}
