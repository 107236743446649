<div style="width:100%;">

  <app-header [isMobile]="isMobile"></app-header>

  <app-styles [isMobile]="isMobile"></app-styles>

  <app-locations></app-locations>

  <app-extras [isMobile]="isMobile"></app-extras>

  <app-contact></app-contact>

  <app-footer></app-footer>
  
</div>