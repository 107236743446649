<!-- DESKTOP START -->
<div class="desktop">

  <div class="container-grid">
  
    <div class="flex align-center justify-center">
      <a href="https://www.instagram.com/botanica_picnics/" target="_blank"><img class="m-r-h" src="../../../assets/insta.png" alt="Instagram logo"></a>
      <p><a href="https://www.instagram.com/botanica_picnics/" target="_blank">botanica_picnics</a></p>
    </div>
  
    <div class="flex align-center justify-center">
      <h1 class="clicky" (click)="navHeader()">Botanica Picnics</h1>
    </div>
  
    <div class="flex align-center justify-center">
      <!-- <p class="m-r-1h">Cookies</p>
      <p>Privacy</p> -->
    </div>
  
  </div>

</div>
<!-- DESKTOP END -->

<!-- MOBILE START -->
<div class="mobile">

  <div class="container">
    <h1 class="t-align-c clicky" (click)="navHeader()">Botanica Picnics</h1>
    <p class="t-align-c"><a href="https://www.instagram.com/botanica_picnics/" target="_blank">botanica_picnics</a></p>
  </div>

</div>
<!-- MOBILE END -->