import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup = null

  formMessage: any = { message: '' }

  constructor(
    private builder: FormBuilder,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.contactForm = this.form.build()

    this.contactForm.valueChanges.subscribe(_ => {
      console.log(this.contactForm.value)
    })
  }

  downloadPricing = () => {
    console.log('Download')
    const pdfUrl = 'https://keiken-bucket.s3-ap-southeast-2.amazonaws.com/Botanica-Price-List-1.pdf';
    const pdfName = 'Botanica Price List';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  form = {
    submit: (): void => {
      console.log(this.contactForm.invalid)
      if (this.contactForm.invalid) { this.formMessage.message = "Please fill out all required form fields."; return; }

      let request = {
        to: ['avaloncooper@hotmail.com'],
        cc: [],
        bcc: ['luke.mcwha@gmail.com'],
        subject: 'Picnic Booking',
        attachments: [],
      }

      this.http.post('https://anarpm60ri.execute-api.ap-southeast-2.amazonaws.com/production/api/123/lead/send', { ...request, ...this.contactForm.value })
        .subscribe((data) => {
          this.formMessage.message = "Thank you for enquiring with Botanica. We will contact you shortly."
          console.log(data)
        })

    },
    build: (): FormGroup => {
      return this.builder.group({
        name: ['', Validators.compose([Validators.required])],
        phone: ['', Validators.compose([Validators.required])],
        email: ['', Validators.compose([Validators.required])],
        package: ['', Validators.compose([Validators.required])],
        partySize: ['', Validators.compose([Validators.required])],
        theme: ['', Validators.compose([Validators.required])],
        optionalExtras: [''],
        location: ['', Validators.compose([Validators.required])],
        date: ['', Validators.compose([Validators.required])],
        startTime: ['', Validators.compose([Validators.required])],
        endTime: ['', Validators.compose([Validators.required])],
        notes: [''],
      })
    }
  }

}
