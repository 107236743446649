<!-- DESKTOP -->
<div class="desktop" id="contact">

  <form [formGroup]="contactForm">

    <div class="container">

      <div class="flex justify-space-between">
        <h1 class="m-b-h">Contact</h1>
        <button (click)="downloadPricing()" class="book-button clicky">View Pricing</button>
      </div>

      <p class="m-b-1h">If you have any questions or queries don’t hesitate to contact us via Instagram or the form
        below.</p>

      <div class="button-container m-b-1">
        <input formControlName="name" class="input-text input input-med" placeholder="Name *" type="text">
        <input formControlName="phone" class="input-text input input-med" placeholder="Phone Number *" type="text">
        <input formControlName="email" class="input-text input input-med" placeholder="Email Address *" type="text">
      </div>

      <div class="button-container m-b-1">

        <select formControlName="package" class="input-text input input-sml">
          <option value="" disabled selected>Package *</option>
          <option>Connect (1-6 guests)</option>
          <option>Gather (6-12 guests)</option>
        </select>

        <select formControlName="partySize" class="input-text input input-x-sml">
          <option value="" disabled selected>Party Size *</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
          <option>9</option>
          <option>10</option>
          <option>11</option>
          <option>12</option>
        </select>

        <select formControlName="theme" class="input-text input input-med">
          <option value="" disabled selected>Theme *</option>
          <option>Earth Glow</option>
          <option>Naturalis</option>
          <option>Coastal Air</option>
        </select>

        <select formControlName="optionalExtras" class="input-text input input-med">
          <option value="" disabled selected>Optional Extras</option>
          <option>Cricket Set</option>
          <option>Plating & Crockery</option>
          <option>Bocce Set</option>
          <option>Place Cards</option>
          <option>Log Blocks</option>
          <option>Drinks Ice Bath</option>
          <option>Sports Pack</option>
          <option>Speakers</option>
        </select>

      </div>

      <div class="button-container m-b-1">
        <input formControlName="location" class="input-text input input-x-lrg" style="margin-right:1%;" type="text"
          placeholder="Picnic Location *">
        <input formControlName="date" appDateMask maxlength="10" class="input-text input input-x-sml" type="text"
          placeholder="dd/mm/yyyy">
        <input formControlName="startTime" class="input-text input input-x-sml" type="time" placeholder="Start Time *">
        <input formControlName="endTime" class="input-text input input-x-sml" type="time" placeholder="End Time *">
      </div>

      <div>
        <textarea formControlName="notes" class="m-b-1 input-text detail-area" placeholder="Enquiry"></textarea>
      </div>

      <button (click)="form.submit()" class="book-button clicky">Submit</button>
      <p class="m-t-1" *ngIf="formMessage.message !== ''">{{ formMessage.message }}</p>
    </div>

  </form>

</div>

<!-- DESKTOP END -->


<!-- MOBILE -->

<div class="mobile" id="contact2">

  <form [formGroup]="contactForm">

    <div class="container">

      <h1 class="m-b-h">Contact</h1>

      <p class="m-b-1h">If you have any questions or queries don’t hesitate to contact us via Instagram or the form
        below.</p>

      <input formControlName="name" class="m-b-h input-text input input-full" placeholder="Name" type="text">
      <input formControlName="phone" class="m-b-h input-text input input-full" placeholder="Phone Number" type="text">
      <input formControlName="email" class="m-b-h input-text input input-full" placeholder="Email Address" type="text">

      <select formControlName="package" class="m-b-h input-text input input-full">
        <option value="" disabled selected>Package</option>
        <option>Connect (1-6 guests)</option>
        <option>Gather (6-12 guests)</option>
      </select>

      <select formControlName="partySize" class="m-b-h input-text input input-full">
        <option value="" disabled selected>Party Size</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option>
        <option>11</option>
        <option>12</option>
      </select>

      <select formControlName="theme" class="m-b-h input-text input input-full">
        <option value="" disabled selected>Theme</option>
        <option>Earth Glow</option>
        <option>Naturalis</option>
        <option>Coastal Air</option>
      </select>

      <select formControlName="optionalExtras" class="m-b-h input-text input input-full">
        <option value="" disabled selected>Optional Extras</option>
        <option>Cricket Set</option>
        <option>Plating & Crockery</option>
        <option>Bocce Set</option>
        <option>Place Cards</option>
        <option>Log Blocks</option>
        <option>Drinks Ice Bath</option>
        <option>Sports Pack</option>
        <option>Speakers</option>
      </select>

      <input formControlName="location" class="m-b-h input-text input input-full" style="margin-right:1%;" type="text"
        placeholder="Picnic Location">
      <!-- <input appDateMask maxlength="10" class="m-b-h input-text input input-full" type="text" placeholder="dd/mm/yyyy"> -->
      <input formControlName="date" class="m-b-h input-text input input-full" type="date">
      <input formControlName="startTime" class="m-b-h input-text input input-full" type="time" placeholder="Start Time">
      <input formControlName="endTime" class="m-b-h input-text input input-full" type="time" placeholder="End Time">

      <textarea formControlName="notes" class="m-b-1 input-text detail-area" placeholder="Enquiry"></textarea>

      <button [disabled]="contactForm.invalid" (click)="form.submit()" class="book-button clicky">Submit</button>
      <p *ngIf="formMessage.message !== ''">{{ formMessage.message }}</p>
    </div>

  </form>

</div>

<!-- MOBILE END -->