<app-full-screen-background-video></app-full-screen-background-video>
<div class="container" id="header">

  <div class="menu-grid">
  
    <div class="heading m-b-1">
      <h1>Botanica Picnics</h1>
    </div>
    
    <div class="menu-items flex align-center justify-space-evenly">
      <a class="clicky" (click)="navStyle()">Styles</a>
      <a class="clicky" (click)="navExtras()">Extras</a>
      <a class="clicky" (click)="navContact()">Contact</a>
      <button id="book-button" class="desktop clicky" (click)="navContact()">Book</button>
    </div>
  
  </div>

  
  <div class="content">
    <p class="m-b-1">Melbourne Pop Up Picnic Experiences</p>
    <h1 class="m-b-1">Re-connect and enjoy the natural <br class="desktop"/>beauty of Melbourne with effortless <br class="desktop"/>picnic experiences by Botanica.</h1>
    <a href="https://www.instagram.com/botanica_picnics/" target="_blank"><img class="m-b-1" src="../../../assets/insta.png" alt="Instagram logo"></a>

    <div class="mobile flex align-center justify-center">
      <button id="book-button" class="mobile clicky" (click)="navContact()">Book</button>
    </div>
    
  </div>
  
</div>