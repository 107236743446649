import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
 
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { StylesComponent } from './components/styles/styles.component';
import { LocationsComponent } from './components/locations/locations.component';
import { ExtrasComponent } from './components/extras/extras.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { DateDirectiveDirective } from './components/contact/date-directive.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullScreenBackgroundVideoComponent } from './components/full-screen-background-video/full-screen-background-video.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    StylesComponent,
    LocationsComponent,
    ExtrasComponent,
    ContactComponent,
    FooterComponent,
    DateDirectiveDirective,
    FullScreenBackgroundVideoComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
