<!-- DESKTOP VIEW -->

<div class="desktop" id="extras">
  <div class="container-grid">

    <div>
      <img class="extras-img" src="../../../assets/extrasimg.png" alt="Image of soccer ball">
    </div>

    <div>
      <h1 class="m-b-1">Extras</h1>
      <p class="m-b-1">Add some extra sparkle to the party with any <br />combination of our optional extras.</p>

      <div class="item-grid">
        <h2>Cricket Set</h2>
        <h2>Plating & Crockery</h2>
        <h2>Bocce Set</h2>
        <h2>Place Cards</h2>
        <h2>Log Blocks</h2>
        <h2>Drinks Ice Bath</h2>
        <h2>Sports Pack</h2>
        <h2>Speakers</h2>
      </div>

      <button class="book-button clicky" (click)="navContact()">Book</button>
    </div>

  </div>
</div>


<!-- END DESKTOP -->

<!-- MOBILE VIEW -->

<div class="mobile" id="extras2">
  <div class="container-grid">

    <div class="m-b-1 flex align-center justify-space-between">
      <h1>Extras</h1>
      <button class="book-button clicky" (click)="navContact()">Book</button>
    </div>

    <div>
      <p class="m-b-1">Add some extra sparkle to the party with any combination of our optional extras.</p>
    </div>

    <div class="flex align-center justify-center">
      <img class="extras-img" src="../../../assets/extrasimg.png" alt="Image of soccer ball">
    </div>

    <div class="m-t-1h item-grid">
      <h3>Cricket Set</h3>
      <h3>Plating & Crockery</h3>
      <h3>Bocce Set</h3>
      <h3>Place Cards</h3>
      <h3>Log Blocks</h3>
      <h3>Drinks Ice Bath</h3>
      <h3>Sports Pack</h3>
      <h3>Speakers</h3>
    </div>

  </div>
</div>

<!-- END MOBILE -->