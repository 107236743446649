import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-full-screen-background-video',
  templateUrl: './full-screen-background-video.component.html',
  styleUrls: ['./full-screen-background-video.component.scss']
})
export class FullScreenBackgroundVideoComponent implements OnInit, AfterViewInit {
  @ViewChild('vid') element: ElementRef
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() : void {
    setTimeout(() => {
      // this.element.nativeElement.play()
    }, 1000)
    
  }
}
